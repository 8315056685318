.App {
  padding: 2em;
}

.Button-Bar {
  display: flex;
}

.Button-Bar .Button {
  margin-right: 1em;
}